var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "数据源编码",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["code", _vm.validatorRules.code],
                        expression: "['code', validatorRules.code]",
                      },
                    ],
                    attrs: {
                      placeholder: "请输入数据源编码",
                      disabled: !!_vm.model.id,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "数据源名称",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["name", _vm.validatorRules.name],
                        expression: "['name', validatorRules.name]",
                      },
                    ],
                    attrs: { placeholder: "请输入数据源名称" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "数据库类型",
                  },
                },
                [
                  _c("j-dict-select-tag", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["dbType", _vm.validatorRules.dbType],
                        expression: "['dbType', validatorRules.dbType]",
                      },
                    ],
                    attrs: {
                      placeholder: "请选择数据库类型",
                      "dict-code": "database_type",
                      triggerChange: "",
                    },
                    on: { change: _vm.handleDbTypeChange },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "驱动类",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["dbDriver", _vm.validatorRules.dbDriver],
                        expression: "['dbDriver', validatorRules.dbDriver]",
                      },
                    ],
                    attrs: { placeholder: "请输入驱动类" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "数据源地址",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["dbUrl", _vm.validatorRules.dbUrl],
                        expression: "['dbUrl', validatorRules.dbUrl]",
                      },
                    ],
                    attrs: { placeholder: "请输入数据源地址" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "数据库名称",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["dbName", _vm.validatorRules.dbName],
                        expression: "['dbName', validatorRules.dbName]",
                      },
                    ],
                    attrs: { placeholder: "请输入数据库名称" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "用户名",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["dbUsername", _vm.validatorRules.dbUsername],
                        expression: "['dbUsername', validatorRules.dbUsername]",
                      },
                    ],
                    attrs: { placeholder: "请输入用户名" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "密码",
                  },
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 8 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 21 } },
                        [
                          _c("a-input-password", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "dbPassword",
                                  _vm.validatorRules.dbPassword,
                                ],
                                expression:
                                  "['dbPassword', validatorRules.dbPassword]",
                              },
                            ],
                            attrs: { placeholder: "请输入密码" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.handleTest },
                            },
                            [_vm._v("测试")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "备注",
                  },
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["remark", {}],
                        expression: "['remark', {}]",
                      },
                    ],
                    attrs: { placeholder: "请输入备注" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }